import React from "react";
import uuid from "react-uuid";
import {
    formatSize,
    formatFrameColor,
    formatProduct
} from "../../utils/functionUtils";
import {shopifyDashboardServerHost} from "../../config/axiosConfig";
import {useOrdersContext} from "../../context/orders/context";

const ComponentToPrint = React.forwardRef((props, ref) => {

    const {productNames} = useOrdersContext()
    return (
        <div className={'printContainer'} style={{margin: "0", padding: "0"}} ref={ref}>
            {props.orders.map((order) => {
                let imgToRender = order.designImages[0]
                try {
                    if (!(imgToRender.includes('http') || imgToRender.includes('base64'))) imgToRender = `${shopifyDashboardServerHost}/renderImg?imageUrl=${imgToRender}&store=${order.store}&date=${order.timestamp}`
                } catch (e) {

                    console.log(imgToRender, order)
                }
                return (
                    <div key={uuid()}>
                        <div className="page-break"/>
                        <div className={'order_container toPrint'}
                             style={{transform: "rotate(180deg)", marginTop: 240}}>
                            <div className={'text__to__print__container'}>
                                <div>{order.store === 'dog-land' ? 'ST ' : ''}<h1
                                    style={{fontSize: 32}}>Order {order.orderNumber}</h1></div>
                                <br/>
                                <div>{order.shippingAddress.name}</div>
                                <div>{order.shippingAddress.address1} {order.shippingAddress.address2}</div>
                                <div>{order.shippingAddress.city} {order.shippingAddress.province_code} {order.shippingAddress.zip}</div>
                                <div>{order.shippingAddress.country}</div>
                                <div>{order.shippingAddress.phone}</div>
                                <br/>
                                {order.lineItems.map((item) => {
                                    let products = Array.from(Array(item.count).keys());
                                    return <div key={uuid()}>
                                        {products.map((prod) => {
                                            let size
                                            try {
                                                size = formatSize(order.orderNumber, item.portraitSize)
                                            } catch (e) {

                                            }
                                            return (
                                                <div key={uuid()}>
                                                    <div>Size: {item?.portraitSize || order?.portraitSize || size} </div>
                                                    <small> Color: (Legacy) {formatFrameColor(order, item.portraitSize)}</small>
                                                    <div>Props: {item.props?.length === 0 ? " ||  No Props" :
                                                        <div>{item.props?.map(prp => (<>
                                                            <strong>{prp.name}</strong>: <u>{prp.value}</u> || </>))}</div>}</div>
                                                    <div>Product: {formatProduct(order.orderNumber, item.productName, productNames)}</div>
                                                    <br/>
                                                </div>
                                            )
                                        })}
                                    </div>

                                })}
                                {order.lineItems.length ===0 && (<div>
                                    <div key={uuid()}>
                                        <div>Size: {order?.portraitSize} </div>
                                        <small> Color: (Legacy) {formatFrameColor(order, order?.portraitSize)}</small>
                                        <div>Props: {order.props?.length === 0 ? " ||  No Props" :
                                            <div>{order.props?.map(prp => (<>
                                                <strong>{prp.name}</strong>: <u>{prp.value}</u> || </>))}</div>}</div>
                                        <div>Product: {formatProduct(order.orderNumber, order.productName, productNames)}</div>
                                        <br/>
                                    </div>

                                </div>)}

                            </div>
                            <div className={'image__to__print__container'}>
                                <img className={'image__to__print'} src={imgToRender}/>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
});

export default ComponentToPrint;